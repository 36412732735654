import React from 'react';

import Countdown from '@/components/Countdown';
import Button from '@/components/Button';
import StickyFooter from '@/components/StickyFooter';

import { breakPoints } from '@/theme';
import { useWindowSize } from '@/hooks';
import { globals, routes, translations } from '@/constants';
import { getBundlePrice, isPromotionCompleted, isButtonBuyDisabled } from '@/utils';

import { StickyFooterText } from '../styled';

export const StickyCountdown = () => {
    const hidePromo = isPromotionCompleted();
    const hideButton = isButtonBuyDisabled();
    const windowSize = useWindowSize();
    const isMobile = windowSize.width <= breakPoints.mobileBreakPoint;

    return (
        <StickyFooter>
            { !isMobile &&
                <StickyFooterText>{ hidePromo ? translations.footerCountdownTextCompleted : translations.footerCountdownText }</StickyFooterText> }
            <Countdown dateEnd={ globals.PROMOTION_DATE_END }/>
            <Button
                as="a"
                dataId="button-count-down"
                href={ !hideButton ? routes.EBOOKS_PACK_URL_BUY : undefined }
                rel="nofollow"
                variant="action"
                isDisabled={ hideButton }
            >
                { `${ translations.commonButtonBuyNowText } ${ getBundlePrice }` }
            </Button>
        </StickyFooter>
    );
};

export default StickyCountdown;
