export const getFeatureFlag = ( featureName ) => {
    try {
        // Retrieve the feature flags from local storage
        const featureFlagsString = localStorage.getItem( 'featureFlags' );

        // Parse the JSON string to an object
        const featureFlags = JSON.parse( featureFlagsString ) || {};

        // Check if the specified feature exists in the feature flags
        if ( featureFlags.hasOwnProperty( featureName ) ) {
            // Return the value of the specified feature
            return featureFlags[ featureName ];
        } else {
            // Return a default value or handle the case where the feature is not found
            return null;
        }
    } catch ( error ) {
        // Handle any errors that might occur during the process
        console.error( 'Error retrieving feature flag:', error );
        return null;
    }
};

export default getFeatureFlag;
