import { ebookStats, globals } from '@/constants';
import { getBundlePrice } from '@/utils';
export const translations = {
    navigationEbooks: 'E-BOOKI',
    navigationFAQ: 'FAQ',
    navigationAbout: 'O HEALTHY PACK!',
    navigationTestimonials: 'OPINIE',
    navigationTermsAndConditions: 'Regulamin',
    navigationPrivacy: 'Polityka Prywatności',
    navigationButtonOpen: 'Pokaż menu',
    navigationButtonClose: 'Zamknij menu',
    bannerHeadline01: 'Sięgnij po wiedzę! Inspirację dla',
    bannerHeadline02: 'zdrowego życia.',
    bannerText01: `Odkryj tajniki zdrowego odżywiania, które znajdziesz w `,
    bannerText02: `inspirujących e-bookach!`,
    bannerText03: `Cenne porady na temat diety, zdrowe przepisy, plany posiłków oraz cenne wskazówki, które pomogą Ci w utrzymaniu zdrowego stylu życia!`,
    bannerText04: `To wszystko znajdziesz w ${globals.SITE_NAME}!`,
    bannerButtonText: 'KUP PACZKĘ',
    commonButtonBuyNowText: 'KUP PACZKĘ TERAZ',
    commonButtonBuyNowTextWithPrice: `KUP PACZKĘ ZA ${getBundlePrice}`,
    pricingHeadlineItem01: globals.SITE_NAME,
    pricingHeadlineItem02: 'Cena Regularna',
    pricingContentBulletPoint01: 'Zdrowe Przepisy',
    pricingContentBulletPoint02: `Poradniki Ćwiczeń`,
    pricingContentBulletPoint03: `Zdrowie Psychiczne`,
    pricingContentBulletPoint04: `Sportowy Tryb Życia`,
    pricingContentBulletPoint05: `Prawidłowe Nawyki Żywieniowe`,
    footerCountdownText: 'Taka okazja się drugi raz nie trafia, Pośpiesz się!',
    footerCountdownTextCompleted: 'Sezeon na awokado się skończył. Oferta zakończona!',
};

export default translations;
