import React, { useState } from 'react';

import { setFeatureFlag } from '@/utils';

import Text from '@/components/Text';
import Input from '@/components/Input';
import Button from '@/components/Button';
import Section from '@/components/Section';
import Headline from '@/components/Headline';
import Logotype from '@/components/Logotype';
import { Col, Row } from '@/components/Layout';

import { FormWrapper } from '../styled'
import { translations } from '../constants/translations';

const PasswordProtection = () => {
    const [ password, setPassword ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const handleOnChange = ( e ) => {
        setPassword( e.target.value );
    };

    const handleOnSubmit = ( e ) => {
        e?.preventDefault();
        if ( password === 'mexico' ) {
            setFeatureFlag( 'isDemo', 'correct' );
            window.location.reload();
        } else {
            setError( translations.passwordProtectionError );
        }
    };

    return (
        <>
            <Section variant="transparent">
                <Row>
                    <Col number={ 1 }>
                        <Logotype/>
                        <br/>
                        <Headline align="left" type="secondary">{ translations.passwordProtectionHeadline }</Headline>
                        <br/>
                        <Text size="medium">Podaj hasło, żeby mieć dostęp do strony www, Alohomora nie działa :)</Text>
                        <FormWrapper onSubmit={ handleOnSubmit }>
                            <Input type="password" onChange={ handleOnChange } value={ password }/>
                            <Text size="medium">{ error }</Text>
                            <Button isFullWidth type="submit" onClick={ handleOnSubmit }>{ translations.buttonSend }</Button>
                        </FormWrapper>
                    </Col>
                </Row>
            </Section>
        </>
    );
};

export default PasswordProtection;
