export const SITE_NAME = `HealthyPack`;
export const SITE_CONTACT_EMAIL = 'pomoc@healthypack.shop';
export const SITE_SOCIAL_LINK_IG = 'https://instagram.com/healthypack.shop';

export const DOWNLOAD_URL = '';
// First Promo 2024-03-07 23:59:59 +1h (Poland)
// export const PROMOTION_DATE_END = 1709852399000;
// Second Promo 2024-04-11 23:59:59 +1h (Poland)
export const PROMOTION_DATE_END = 1712872799000;


export const COOKIE_CONSENT = 'consentMode';
