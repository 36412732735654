import styled from 'styled-components';


export const BannerImageWrapper = styled.div`
    max-width: 100%;
    position: relative;
    top: -250px;
    left: -50px;

    img {
        width: 200%;
    }

    @media only screen and (max-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
       top: -30px;
    }
`;

export default BannerImageWrapper;
