import { createGlobalStyle } from 'styled-components';
import 'normalize.css';
import bannerBackground from '../../assets/banner.png';

const Normalize = createGlobalStyle`

    body {
        font-family: ${ ( { theme } ) => theme.fontFamilyPrimary };
        font-size: ${ ( { theme } ) => theme.fontSizeS };
        font-weight: ${ ( { theme } ) => theme.fontWeightRegular };
        background: ${ ( { theme } ) => theme.ui01 } url(${bannerBackground}) no-repeat;
        background-size: 50%;
        background-position-x: 100%;
        background-position-y: 0;
    }
    
    a {
        text-decoration: none ;
    }
    
    p a {
        text-decoration: underline;
        color: ${ ( { theme } ) => theme.text01 };
    }

    * {
        box-sizing: border-box;
    }
     button {
         cursor: pointer;
         background: none;
         border: none;
         padding: 0;
         margin: 0;
         color: inherit;
     }
`;

export default Normalize;
