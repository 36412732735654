import { ebookStats } from '@/constants';

export const translations = {
    advantagesSubHeadline: `PACZKA ${ ebookStats.EBOOK_TOTAL_EBOOKS } E-BOOKÓW`,
    advantagesHeadline: 'Tysiące Zdrowych Korzyści',

    advantagesItemTitle01: 'Sięgnij po zdrowie',
    advantagesItemText01: `Odkryj różnorodne inspiracje i znajdź swoją idealną drogę do zdrowia!`,

    advantagesItemTitle02: `Oszczędzasz ${ ebookStats.EBOOK_SAVING }`,
    advantagesItemText02: `Wyjątkowa wartość, w zaskakująco niskiej cenie`,

    advantagesItemTitle03: `${ ebookStats.EBOOK_TOTAL_RECOMMENDATION } Polecajek`,
    advantagesItemText03: 'Setki miejsc, które zostały sprawdzone przez podróżników',

    advantagesItemTitle04: `${ ebookStats.EBOOK_TOTAL_EBOOKS } e-booków`,
    advantagesItemText04: `Kompletna biblioteka zdrowotna w zasięgu ręki`,
};

export default translations;
